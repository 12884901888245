.about-heading{
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
}

.about-container{
    padding: 0 2rem;
}

.about .img-wrapper{
    max-width: 100%;
    margin-bottom: 20px;
    padding: 0 0.5rem;
}

.img-container{
    position: relative;
    max-width: 100%;
}

.about .img-wrapper img{
    width: 100%;
    height: 300px;
}

.about .img-wrapper .overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0, 0, 0, 0.8);
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;

}

.about .img-wrapper:hover .overlay {
    width: 100%;
}

.about .about-container .img-wrapper .img-container .overlay{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
}

.about .about-container .img-wrapper .img-container .overlay h1{
    font-size: 2rem;
    font-family: var(--secondary_font);
}

.about .about-container .img-wrapper .img-container .overlay p{
    font-size: 1rem;
    font-family: var(--primary_font);
    padding: 0 1rem;
    text-align: center;
}




