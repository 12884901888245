@import url(https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bakbak+One&family=Roboto+Slab:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top{
    box-shadow: 0 0 5px 1px rgb(37, 37, 37);
    background-color: rgb(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    width: 100%;

}

.top_nav{
    padding: 1rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top_nav-logo{
    max-width: 150px;
}

.top_nav-logo img{
    width: 100%;
    object-fit: fill;
}

.top .top_nav .top_nav-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top .top_nav .top_nav-navbar a{
    font-family: var(--primary_font);
    font-weight: bold;
    font-size: 1.2rem;
    text-decoration: none;
    margin: 0 0.8rem;
    color: var(--primary_color);
    position: relative;
    z-index: 1;
}

.top .top_nav .top_nav-navbar a::before{
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   border-bottom: 3px solid var(--primary_color);
   height: 32px;
   z-index: -1;
   transform: scaleY(0);
   transform-origin: bottom;
   transition: transform 200ms ease-in-out;
}

.top .top_nav .top_nav-navbar a:hover::before,
.top .top_nav .top_nav-navbar a:focus::before {
    transform: scaleY(1);
   
}

@media only screen and (max-width: 650px) {
    .top_nav {
       padding: 1rem;
    }
}

@media only screen and (max-width: 450px) {
    .top .top_nav .top_nav-navbar a{
        font-family: var(--primary_font);
        font-weight: bold;
        font-size: 0.8rem;
        text-decoration: none;
        margin: 0 0.2rem;
    }

    .top .top_nav .top_nav-navbar a::before{
        height: 25px;
    }
    
}

.homepage{
    max-width: 100%;
    position: relative;
    /* position: relative; */
}
.homepage img{
    width: 100%;
    height: 95vh;
    object-fit: cover;
}

.homepage a img{
    width: 55px;
    height: 55px;
    border: 1px solid rgb(59, 59, 59, 0.7);
    border-radius: 50%;
    background-color: var(--primary_color) !important;
    position: absolute;
    left: 0;
    bottom: -25px;
    right: 0;
    margin: 0 auto;
    cursor: pointer;
}

.homepage-overlay{
    position: absolute;
    width: 100%;
    height: 95vh;
    background-color: rgb(0, 0, 0, 0.5);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-overlay .outer{
    color: white;
    font-family: var(--secondary_font);
    text-align: center;
}

.homepage-overlay .outer h1{
    font-family: var(--secondary_font);
    font-size: 5rem;
    position: relative;
    animation: slideHeading_1 1s ease-in-out;
}

.homepage-overlay .outer h2{
    font-family: var(--secondary_font);
    font-size: 3rem;
    animation: slideHeading_2 1.5s ease-in-out;
}

/* .homepage-overlay .outer h2 .metal{
    animation: color_float 5s infinite ease-in-out;
} */

@keyframes slideHeading_1{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes slideHeading_2{
    0%{
        transform: scaleY(0);
    }
    80%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}


@media only screen and (max-width: 950px) {
    .homepage-overlay .outer h1 {
       font-size: 3rem;
    }

    .homepage-overlay .outer h2 {
       font-size: 2rem;
    }
}

@media only screen and (max-width: 450px) {
    .homepage-overlay .outer h1 {
       font-size: 2rem;
    }

    .homepage-overlay .outer h2 {
       font-size: 1.2rem;
    }
}


/* @keyframes color_float {
    0%{ 
        transform: translateY(0);
     }
    15%{ 
        transform: translateY(0);
     }
    20%{ 
        transform: translateY(10px);
     }
    35%{ 
        transform: translateY(10px);
     }
    40%{ 
        transform: translateY(5px);
     }
    55%{ 
        transform: translateY(5px);
     }
    60%{ 
        transform: translateY(0);
     }
    75%{ 
        transform: translateY(-10px);
     }
    80%{ 
        transform: translateY(-10px);
     }
    95%{ 
        transform: translateY(-5px);
     }
    100%{ 
        transform: translateY(0px);
     }
} */
.about-heading{
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
}

.about-container{
    padding: 0 2rem;
}

.about .img-wrapper{
    max-width: 100%;
    margin-bottom: 20px;
    padding: 0 0.5rem;
}

.img-container{
    position: relative;
    max-width: 100%;
}

.about .img-wrapper img{
    width: 100%;
    height: 300px;
}

.about .img-wrapper .overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0, 0, 0, 0.8);
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;

}

.about .img-wrapper:hover .overlay {
    width: 100%;
}

.about .about-container .img-wrapper .img-container .overlay{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
}

.about .about-container .img-wrapper .img-container .overlay h1{
    font-size: 2rem;
    font-family: var(--secondary_font);
}

.about .about-container .img-wrapper .img-container .overlay p{
    font-size: 1rem;
    font-family: var(--primary_font);
    padding: 0 1rem;
    text-align: center;
}






.contact {
    width: 100%;
}

.contact-heading{
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
}

.contact .container .row {
    padding: 1rem 2rem 3rem;
}

.contact .container .row > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.contact .container .row .contact-img{
    width: 80%;
    animation: floating 3s infinite ease-in-out;
    
}


.contact .container .row div + div{
    text-align: center;
    padding: 1rem;
}

@keyframes floating{
    0%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(-20px);
    }
    60%{
        transform: translateY(-30px);
    }
    85%{
        transform: translateY(-40px);
    }
    100%{
        transform: translateY(0);
    }
}

.contact .container .row .contact-form .input-field {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 1rem;
}

.contact .container .row .contact-form .input-field label {
    font-size: 1.1rem;
    font-family: var(--primary_font);
    font-weight: bold;
}

.contact .container .row .contact-form .send-btn {
    width: 100%;
    font-family: var(--secondary_font);
    font-size: 1.5rem;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact .container .row .contact-form .send-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    bottom: 0;
    z-index: -1;
    background-color: var(--secondary_color);
    transform: scaleX(0);
    transition: transform .4s ease-in-out;
}

.contact .container .row .contact-form .send-btn:hover::before,
.contact .container .row .contact-form .send-btn:focus::before {
    transform: scaleX(1);
}

.contact .container .row .contact-form .send-btn:hover,
.contact .container .row .contact-form .send-btn:focus {
    color: white;
}

.contact .container .row .contact-form .submit_loader::after {
    position: absolute;
    content: '';
    z-index: -1;
    border: 8px solid var(--tetra_color);
    border-radius: 50%;
    border-top: 8px solid black;
    width: 35px;
    height: 35px; /* Safari */
    animation: spin 1.2s linear infinite;
  }
  
  /* Safari */
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.copyright{
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
}

.copyright h1{
    color: white;
    font-family: var(--primary_font);
    font-size: 1rem;
    margin-top: 0.5rem;
}

@media only screen and (max-width: 550px) {
    .copyright h1{
        font-size: 0.8rem;
        text-align: center;
    }
  }
.separator .wave{
    width: 100%;
    height: 80px;
}
.footer{
    background-color: var(--secondary_color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 3rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    font-family: var(--primary_font);
    font-size: 1rem;
}

.footer .address, .phone{
    display: flex;
    align-items: center;
}

.footer .address img{
    width: 35px;
}

.footer .address h4{
    font-weight: bold;
    margin: 0;
    width: 400px;
    text-align: center;
}

.footer .phone img{
    width: 35px;
}

.footer .phone, h4{
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
}

@media only screen and (max-width: 700px) {
    .footer {
      flex-direction: column;
      text-align: center;

    }
    
    .footer .address h4{
        width: unset;
    }
    
    .footer .address, .phone{
       margin-bottom: 1.5rem;
       width: 90%;
    }

    .footer .phone{
        justify-content: center;
    }


  }


body, html, #root {
  height: 100%;
}

:root{
  --white: white;
  --primary_color: #dedede;
  --secondary_color: #b7b7b7;
  --tertiary_color: #848484;
  --tetra_color: #5a5a5a;
  --hex_color: #2e2e2e;
  --black: black;
  --primary_font: 'Roboto Slab', serif;
  --secondary_font: 'Bakbak One', cursive;

}


