.homepage{
    max-width: 100%;
    position: relative;
    /* position: relative; */
}
.homepage img{
    width: 100%;
    height: 95vh;
    object-fit: cover;
}

.homepage a img{
    width: 55px;
    height: 55px;
    border: 1px solid rgb(59, 59, 59, 0.7);
    border-radius: 50%;
    background-color: var(--primary_color) !important;
    position: absolute;
    left: 0;
    bottom: -25px;
    right: 0;
    margin: 0 auto;
    cursor: pointer;
}

.homepage-overlay{
    position: absolute;
    width: 100%;
    height: 95vh;
    background-color: rgb(0, 0, 0, 0.5);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-overlay .outer{
    color: white;
    font-family: var(--secondary_font);
    text-align: center;
}

.homepage-overlay .outer h1{
    font-family: var(--secondary_font);
    font-size: 5rem;
    position: relative;
    animation: slideHeading_1 1s ease-in-out;
}

.homepage-overlay .outer h2{
    font-family: var(--secondary_font);
    font-size: 3rem;
    animation: slideHeading_2 1.5s ease-in-out;
}

/* .homepage-overlay .outer h2 .metal{
    animation: color_float 5s infinite ease-in-out;
} */

@keyframes slideHeading_1{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes slideHeading_2{
    0%{
        transform: scaleY(0);
    }
    80%{
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(1);
    }
}


@media only screen and (max-width: 950px) {
    .homepage-overlay .outer h1 {
       font-size: 3rem;
    }

    .homepage-overlay .outer h2 {
       font-size: 2rem;
    }
}

@media only screen and (max-width: 450px) {
    .homepage-overlay .outer h1 {
       font-size: 2rem;
    }

    .homepage-overlay .outer h2 {
       font-size: 1.2rem;
    }
}


/* @keyframes color_float {
    0%{ 
        transform: translateY(0);
     }
    15%{ 
        transform: translateY(0);
     }
    20%{ 
        transform: translateY(10px);
     }
    35%{ 
        transform: translateY(10px);
     }
    40%{ 
        transform: translateY(5px);
     }
    55%{ 
        transform: translateY(5px);
     }
    60%{ 
        transform: translateY(0);
     }
    75%{ 
        transform: translateY(-10px);
     }
    80%{ 
        transform: translateY(-10px);
     }
    95%{ 
        transform: translateY(-5px);
     }
    100%{ 
        transform: translateY(0px);
     }
} */